import React, {useEffect, useState} from 'react';
import AdvertisementTable from './AdvertisementTable';
import AppsContainer from '@crema/core/AppsContainer';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {setPage, setSearch} from 'redux/actions/ExclusiveProperty';
import AppsHeader from '@crema/core/AppsContainer/AppsHeader';
import AppsContent from '@crema/core/AppsContainer/AppsContent';
import AppsPagination from '@crema/core/AppsPagination';
import {Input, Button, Typography, Modal} from 'antd';
import './index.style.less';
import AppPageMetadata from '@crema/core/AppPageMetadata';
import ModalCloseConfirm from 'lib/components/ModalCloseConfirm';
import AddAdvertisement from './AddAdvertisement';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {getAdvertisements} from 'redux/actions';

const debounceSearchOrder = AwesomeDebouncePromise(
  (search, dispatch) => {
    dispatch(setSearch(search));
    dispatch(setPage(1));
  },
  500,
  {
    onlyResolvesLast: true,
  },
);

const Advertise = () => {
  const {messages} = useIntl();
  const dispatch = useDispatch();
  const {advertisements, advertisementCount, page, search} = useSelector(
    ({advertisement}) => advertisement,
  );

  const {loading} = useSelector(({common}) => common);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onChange = (page) => {
    dispatch(setPage(page));
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showShowModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    dispatch(getAdvertisements());
  }, [dispatch, search, page]);

  const onSearchOrder = (e) => {
    debounceSearchOrder(e.target.value, dispatch);
  };

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
    };
  }, []);

  return (
    <>
      <AppPageMetadata title='Advertisement' />
      <AppsContainer
        title={messages['sidebar.admin.advertisement']}
        fullView
        type='bottom'>
        <AppsHeader key={'wrap'}>
          <div className='customer-header'>
            <div className='customer-header-input-view'>
              <Input
                id='user-name'
                placeholder='Search'
                type='search'
                onChange={onSearchOrder}
              />
            </div>
            <Typography style={{padding: '0 0.5rem'}}>
              Total: {advertisementCount}
            </Typography>
            <div className='customer-header-right'>
              <Button type='primary' onClick={() => showShowModal()}>
                Add Advertisement
              </Button>
              <AppsPagination
                className='customer-header-pagination'
                pageSize={10}
                current={page}
                count={advertisementCount}
                page={page}
                onChange={onChange}
              />
            </div>
          </div>
        </AppsHeader>
        <AppsContent
          key={'wrap1'}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}>
          <AdvertisementTable loading={loading} propertyData={advertisements} />
        </AppsContent>
        <AppsPagination
          key={'wrap2'}
          className='customer-footer-pagination'
          pageSize={10}
          current={page}
          count={advertisementCount}
          page={page}
          onChange={onChange}
        />
      </AppsContainer>
      <Modal
        title={messages['admin.addAdvertisement']}
        visible={isModalVisible}
        onOk={handleOk}
        footer={false}
        maskClosable={false}
        destroyOnClose={true}
        closeIcon={<ModalCloseConfirm onCancel={handleCancel} />}>
        <AddAdvertisement closeModel={handleCancel} />
      </Modal>
    </>
  );
};

export default Advertise;
