import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {Form, Input, Button, Upload, message, Switch, Image} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import {editAdvertisement} from 'redux/actions';

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 14,
  },
};

function EditAdvertisement({data, closeModel}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(data?.isActive || false);
  const [imageUrl, setImageUrl] = useState(data?.imageName || '');

  const onFinish = (values) => {
    let body = {
      ...values,
      UploadImage: values?.UploadImage?.fileList[0]?.originFileObj,
    };
    const formData = new FormData();
    formData.append('Title', body.Title);
    formData.append('Description', body.Description);
    formData.append('NavigationUrl', body.NavigationUrl);
    formData.append('Order', body.Order);
    formData.append('isActive', checked);
    formData.append('Id', data?.id);

    if (body?.UploadImage) {
      formData.append(
        'UploadImage',
        body?.UploadImage,
        `file-${Date.now()}.png`,
      );
    } else {
      const parts = data?.imageName.split('/');
      const name = parts[parts?.length - 1];
      console.log(name);
      formData.append('imageName', name);
    }
    dispatch(editAdvertisement(formData));
    closeModel();
    form.resetFields();
  };

  const ImageProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,

    customRequest: (e) => {
      e.onSuccess({message: 'Done'});
    },
    beforeUpload: (file) => {
      const isPNG = ['image/png', 'image/jpg', 'image/jpeg'].includes(
        file.type,
      );

      if (!isPNG) {
        message.error(`${file.name} is not a image file`);
      }

      const inSize = file.size < 5000000;

      if (!inSize) {
        message.error(`${file.name} is larger than 5 MB`);
      }

      return (isPNG && inSize) || Upload.LIST_IGNORE;
    },

    onChange(info) {
      const {status} = info.file;

      if (status === 'done') {
        const fileUrl = URL.createObjectURL(info.file.originFileObj);
        setImageUrl(fileUrl);
        message.success(`${info.file.name} file added successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      e.preventDefault();
    },
  };

  return (
    <Form
      form={form}
      className='edit-customer-form'
      name='validate_other'
      {...formItemLayout}
      onFinish={onFinish}
      initialValues={{
        Title: data?.title || '',
        Description: data?.description || '',
        NavigationUrl: data?.navigationUrl || '',
        isActive: data?.isActive || false,
        Order: data?.order || null,
      }}>
      <Form.Item
        name='Title'
        label='Title'
        rules={[{required: true, message: 'Title is required'}]}>
        <Input placeholder='Enter title of Advertisement' />
      </Form.Item>
      <Form.Item
        name='Description'
        label='Description'
        rules={[{required: true, message: 'Description is required'}]}>
        <TextArea placeholder='Enter description of Advertisement' />
      </Form.Item>
      <Form.Item
        name='isActive'
        label='Status'
        rules={[{required: true, message: 'Status is required'}]}>
        <Switch checked={checked} onChange={() => setChecked(!checked)} />
      </Form.Item>
      <Form.Item
        name='NavigationUrl'
        label='Navigation Url'
        rules={[{required: true, message: 'Navigation Url is required'}]}>
        <Input placeholder='Enter Navigation Url of Advertisement' />
      </Form.Item>
      <Form.Item
        name='Order'
        label='Order'
        rules={[
          {required: true, message: 'Order is required'},
          {
            validator: (_, value) => {
              if (value && value <= 0) {
                return Promise.reject(
                  new Error('Order must be greater than 0'),
                );
              }
              return Promise.resolve();
            },
          },
        ]}>
        <Input type='number' placeholder='Enter Order of Advertisement' />
      </Form.Item>
      <Form.Item name=' ' label=' '>
        <div>{imageUrl && <Image width={150} src={imageUrl} />}</div>
      </Form.Item>
      <Form.Item name='UploadImage' label='Image'>
        <Upload {...ImageProps}>
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 10,
        }}>
        <Button col type='primary' htmlType='submit'>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}

EditAdvertisement.propTypes = {
  closeModel: PropTypes.func,
  data: PropTypes.object,
};

export default EditAdvertisement;
