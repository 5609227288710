import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppEnums';
import MortageOptions from './MortageOptions';
import PreQualifiedUser from './PreQualifiedUser';
import Advertise from './Advertise';
const Dashboard = React.lazy(() => import('./Dashboard'));
const Users = React.lazy(() => import('./Users'));
const RoleManagement = React.lazy(() => import('./RoleManagement'));
const ModuleManagement = React.lazy(() => import('./ModuleManagement'));
const PropertyCategoryManagement = React.lazy(() =>
  import('./PropertyCategoryManagement'),
);
const Customers = React.lazy(() => import('./Customers'));
const Lenders = React.lazy(() => import('./Lenders'));
const ExclusiveProperty = React.lazy(() => import('./ExclusiveProperty'));
export const adminPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/admin/dashboard',
    element: <Dashboard />,
    routeName: 'DASHBOARD',
    showRouteIf: true,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/admin/users',
    element: <Users />,
    routeName: 'USERS',
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/admin/modules',
    element: <ModuleManagement />,
    routeName: 'MODULES',
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/admin/roles',
    element: <RoleManagement />,
    routeName: 'ROLES',
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/admin/categories',
    element: <PropertyCategoryManagement />,
    routeName: 'CATEGORIES',
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/admin/customers',
    element: <Customers />,
    routeName: 'CUSTOMERS',
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/admin/lenders',
    element: <Lenders />,
    routeName: 'LENDERS',
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/admin/exclusive-property',
    element: <ExclusiveProperty />,
    routeName: 'EXCLUSIVE PROPERTY',
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/admin/advertisement',
    element: <Advertise />,
    routeName: 'ADVERTISEMENT',
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/admin/mortage-options',
    element: <MortageOptions />,
    routeName: 'MORTAGE OPTIONS',
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/admin/pre-qualified-user',
    element: <PreQualifiedUser />,
    routeName: 'MORTAGE PRE-QUALIFIED USER',
  },
];
