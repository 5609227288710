import React from 'react';
import PropTypes from 'prop-types';
import AppTableContainer from '../../../../@crema/core/AppTableContainer';
import AdvertisementActions from './AdvertisementActions';
import {Switch} from 'antd';
import {useDispatch} from 'react-redux';
import {UpdateAddvertisementStatus} from 'redux/actions';

const AdvertisementTable = ({propertyData, loading}) => {
  const dispatch = useDispatch();

  const handleChange = (checked, record) => {
    dispatch(UpdateAddvertisementStatus(record?.id));
    // call update status api
  };

  const columns = [
    {
      title: 'Image',
      dataIndex: 'imageName',
      key: 'imageName',
      render: (_, record) => {
        return (
          <img
            src={record?.imageName}
            width={50}
            height={40}
            alt='Advertisement'
          />
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => {
        return record?.description?.length > 35
          ? `${record.description.substring(0, 35)}...`
          : record.description;
      },
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'Active/In Active',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (_, record) => {
        return (
          <Switch
            checked={record?.isActive}
            onChange={(e) => handleChange(e, record)}
            value='dsfsf'
          />
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      className: 'customer-table-actions',
      fixed: 'right',
      render: (_, record) => {
        return <AdvertisementActions data={record} />;
      },
    },
  ];

  return (
    <AppTableContainer
      className='customer-table'
      hoverColor
      data={propertyData}
      columns={columns}
      scroll={{x: 'auto'}}
    />
  );
};

export default AdvertisementTable;

AdvertisementTable.defaultProps = {
  propertyData: [],
  loading: false,
};

AdvertisementTable.propTypes = {
  propertyData: PropTypes.array,
  loading: PropTypes.bool,
};
