import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SHOW_MESSAGE,
  GET_ADVERTISEMENTS,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getAdvertisements = (limit = 10) => {
  return (dispatch, getState) => {
    const state = getState().exclusiveproperty;
    dispatch({type: FETCH_START});
    jwtAxios
      .get('/getadvertisements', {
        params: {
          pageNo: state.page,
          pageSize: limit,
          keyword: state.search,
        },
        // search: state.search,
        // page: state.page,
        // limit,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ADVERTISEMENTS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.msg
            ? error?.response?.data?.msg
            : error?.message,
        });
      });
  };
};

export const addAdvertisement = (formData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post('/saveadvertisement', formData)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
          dispatch(getAdvertisements());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message ?? error?.message,
        });
      });
  };
};
export const editAdvertisement = (formData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post('/saveadvertisement', formData)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
          dispatch(getAdvertisements());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message ?? error?.message,
        });
      });
  };
};

export const UpdateAddvertisementStatus = (id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .patch(`/updateadvertisementstatus?advertisementId=${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
          dispatch(getAdvertisements());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message ?? error?.message,
        });
      });
  };
};

export const deleteAdvertise = (id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .delete(`/deleteadvertisement?advertisementId=${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
          dispatch(getAdvertisements());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message ?? error?.message,
        });
      });
  };
};
