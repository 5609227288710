import {
  AiOutlineUsergroupDelete,
  AiOutlineDashboard,
  AiOutlinePropertySafety,
  AiFillSound,
} from 'react-icons/ai';
import {
  MdCategory,
  MdViewModule,
  MdOutlineAssignmentTurnedIn,
  MdOutlineQuestionAnswer,
  MdVerifiedUser,
} from 'react-icons/md';
import {HiOutlineUserGroup, HiOutlineUsers} from 'react-icons/hi';
const routesConfig = [
  {
    id: 'app',
    title: 'Admin',
    messageId: 'sidebar.admin',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        messageId: 'sidebar.admin.dashboard',
        type: 'item',
        icon: <AiOutlineDashboard />,
        path: '/admin/dashboard',
        routeName: 'DASHBOARD',
      },
      {
        id: 'users',
        title: 'Users',
        messageId: 'sidebar.admin.users',
        type: 'item',
        icon: <AiOutlineUsergroupDelete />,
        path: '/admin/users',
        routeName: 'USERS',
      },
      {
        id: 'modules',
        title: 'Modules',
        messageId: 'sidebar.admin.moduleManagement',
        type: 'item',
        icon: <MdViewModule />,
        path: '/admin/modules',
        routeName: 'MODULES',
      },
      {
        id: 'roles',
        title: 'Roles',
        messageId: 'sidebar.admin.roleManagement',
        type: 'item',
        icon: <MdOutlineAssignmentTurnedIn />,
        path: '/admin/roles',
        routeName: 'ROLES',
      },
      {
        id: 'categories',
        title: 'Categories',
        messageId: 'sidebar.admin.propertycategoryManagement',
        type: 'item',
        icon: <MdCategory />,
        path: '/admin/categories',
        routeName: 'CATEGORIES',
      },
      {
        id: 'customers',
        title: 'Customers',
        messageId: 'sidebar.admin.customersManagement',
        type: 'item',
        icon: <HiOutlineUsers />,
        path: '/admin/customers',
        routeName: 'CUSTOMERS',
      },
      {
        id: 'lenders',
        title: 'Lenders',
        messageId: 'sidebar.admin.lendersManagement',
        type: 'item',
        icon: <HiOutlineUserGroup />,
        path: '/admin/lenders',
        routeName: 'LENDERS',
      },
      {
        id: 'exclusive-property',
        title: 'Exclusive Property',
        messageId: 'sidebar.admin.exclusivepropertyManagement',
        type: 'item',
        icon: <AiOutlinePropertySafety />,
        path: '/admin/exclusive-property',
        routeName: 'EXCLUSIVE PROPERTY',
      },
      {
        id: 'advertisement',
        title: 'Advertise',
        messageId: 'sidebar.admin.advertisement',
        type: 'item',
        icon: <AiFillSound />,
        path: '/admin/advertisement',
        routeName: 'ADVERTISEMENT',
      },
      {
        id: 'mortage-options',
        title: 'Mortage Options',
        messageId: 'sidebar.admin.mortageOptions',
        type: 'item',
        icon: <MdOutlineQuestionAnswer />,
        path: '/admin/mortage-options',
        routeName: 'MORTAGE OPTIONS',
      },
      {
        id: 'pre-qualified-user',
        title: 'Mortage Pre-Qualified User',
        messageId: 'sidebar.admin.preQualifiedUser',
        type: 'item',
        icon: <MdVerifiedUser />,
        path: '/admin/pre-qualified-user',
        routeName: 'MORTAGE PRE-QUALIFIED USER',
      },
    ],
  },
];
export default routesConfig;
