import {
  GET_CHID_QUESTIONS,
  GET_QUESTIONS,
  SET_ENQUIRY_TYPE,
  SET_PAGE,
  SET_SEARCH,
} from '../../shared/constants/ActionTypes';

const initialRoleManagement = {
  questions: [],
  childGPQuestions: [],
  questionCount: 0,
  page: 1,
  search: '',
  enquiryType: 1,
};

// TODO: HAVE TO ADD PAGE AND SEARCH IN STATE

const mortageOptionsReducer = (state = initialRoleManagement, action) => {
  switch (action.type) {
    case GET_QUESTIONS:
      return {
        ...state,
        questions: action.payload.data,
        questionCount: action.payload.count,
      };
    case GET_CHID_QUESTIONS:
      return {
        ...state,
        childGPQuestions: action.payload.data?.filter((x) => !x?.isParent),
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };

    case SET_ENQUIRY_TYPE:
      return {
        ...state,
        enquiryType: action.payload,
      };
    default:
      return state;
  }
};

export default mortageOptionsReducer;
