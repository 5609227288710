import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'antd';

function ShowModules({module}) {
  const style = {
    fontWeight: 600,
    marginRight: 10,
  };
  return (
    <div className=''>
      <Row>
        <Col>
          <p style={style}> Name : </p>
        </Col>
        <Col>
          <p>{module.name}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p style={style}> Email : </p>
        </Col>
        <Col>
          <p>{module.email}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p style={style}> Phone Number : </p>
        </Col>
        <Col>
          <p>{module.phoneNumber}</p>
        </Col>
      </Row>

      {module?.userEnquiryDetails?.map((x, index) => {
        return x?.question === 'Home Estimate' ? (
          <p>
            {index + 1}. {x?.answer}
          </p>
        ) : (
          <>
            <Row>
              <Col>
                <p style={style}>Question {index + 1} :</p>
              </Col>
              <Col>{x.question}</Col>
            </Row>
            <Row>
              <Col>
                <p style={style}>Answer {index + 1} :</p>
              </Col>
              <Col>{x.answer}</Col>
            </Row>
          </>
        );
      })}
    </div>
  );
}

export default ShowModules;

ShowModules.propTypes = {
  module: PropTypes.object,
};
