import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../../../shared/constants/ActionTypes';
import jwtAxios, {setAuthToken} from './jwt-api';
import {addProfile, getModules} from 'redux/actions';
const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

let authTimer;

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({children}) => {
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      getProfile();
      dispatch(getModules());
    };

    getAuthUser();
  }, []);

  const signInUser = async ({email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('/login', {
        email,
        password,
      });

      localStorage.setItem('token', data.token);
      setAuthToken(data.token);
      const userdata = await jwtAxios.get('/userprofile');
      let mergeObj = {...userdata.data.data, role: userdata.data.data.roles[0]};
      localStorage.setItem('user_data', JSON.stringify(mergeObj));
      setJWTAuthData({
        user: {
          ...mergeObj,
          token: undefined,
        },
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(addProfile(mergeObj));
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: FETCH_SUCCESS});
      const now = new Date();
      const next = new Date(new Date().setHours(now.getHours() + 8));

      authTimer = setTimeout(() => {
        logout();
      }, next - now);
      localStorage.setItem('TOKEN_EXPIRATION_TIME', next.toJSON());
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });

      dispatch({
        type: FETCH_ERROR,
        payload: error.response.data.msg
          ? error.response.data.msg
          : error.message,
      });
    }
  };

  const getProfile = async () => {
    try {
      const userdata = await jwtAxios.get('/userprofile');
      let mergeObj = {...userdata.data.data, role: userdata.data.data.roles[0]};
      localStorage.setItem('user_data', JSON.stringify(mergeObj));
      setJWTAuthData({
        user: {
          ...mergeObj,
          token: undefined,
        },
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(addProfile(mergeObj));
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });

      dispatch({
        type: FETCH_ERROR,
        payload: error.response.data.msg
          ? error.response.data.msg
          : error.message,
      });
      logout();
    }
  };

  const signUpUser = async ({name, email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('users', {name, email, password});
      localStorage.setItem('token', data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get('/auth');
      setJWTAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const logout = async () => {
    localStorage.removeItem('user_data');
    localStorage.removeItem('token');
    localStorage.removeItem('TOKEN_EXPIRATION_TIME');
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    clearTimeout(authTimer);
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}>
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
        }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
