import PropTypes from 'prop-types';
import {AppRowContainer} from '@crema';
import {Col, Image} from 'antd';
import {Link} from 'react-router-dom';

function ViewAdvertisement({data}) {
  return (
    <AppRowContainer>
      <Col xs={8}>
        <p className='text-secondary'>Title</p>
      </Col>
      <Col xs={16}>
        <p> {data.title ? data.title : ''} </p>
      </Col>
      <Col xs={8}>
        <p className='text-secondary'>Description</p>
      </Col>
      <Col xs={16}>
        <p> {data.description ? data.description : ''}</p>
      </Col>
      <Col xs={8}>
        <p className='text-secondary'>Image</p>
      </Col>
      <Col xs={16}>
        <Image src={data?.imageName} width={160} />
      </Col>
      <Col xs={8}>
        <p className='text-secondary'>Status</p>
      </Col>
      <Col xs={16}>
        <p> {data.isActive ? 'Active' : 'In Active'}</p>
      </Col>
      <Col xs={8}>
        <p className='text-secondary'>Order</p>
      </Col>
      <Col xs={16}>
        <p> {data.order ? data.order : ''}</p>
      </Col>
      <Col xs={8}>
        <p className='text-secondary'>Navigation Url</p>
      </Col>
      <Col xs={16}>
        <p> {data.navigationUrl ? data.navigationUrl : ''}</p>
      </Col>
    </AppRowContainer>
  );
}

export default ViewAdvertisement;
ViewAdvertisement.propTypes = {
  user: PropTypes.object,
};
