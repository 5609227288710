import React, {useState} from 'react';
import {Button, Menu, Dropdown, Modal} from 'antd';
import {MoreOutlined} from '@ant-design/icons';
import {useIntl} from 'react-intl';
import EditAdvertisement from '../EditAdvertisement';
import ShowAdvertisement from '../ViewAdvertisement';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {deleteAdvertise} from 'redux/actions';
import {usePermissions} from 'lib/permissionHook';
import ModalCloseConfirm from 'lib/components/ModalCloseConfirm';
import confirm from 'antd/lib/modal/confirm';

const MODULE_NAME = 'ADVERTISEMENT';

const AdvertisementActions = ({data}) => {
  const {messages} = useIntl();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShowModalVisible, setIsShowModalVisible] = useState(false);

  const {profile} = useSelector(({profile}) => profile);

  const permission = usePermissions(MODULE_NAME, profile);

  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showShowModal = () => {
    setIsShowModalVisible(true);
  };

  const handleShowOk = () => {
    setIsShowModalVisible(false);
  };

  const handleShowCancel = () => {
    setIsShowModalVisible(false);
  };

  const showDeleteModal = () => {
    confirm({
      title: 'Are you sure you want to delete this Advertisement?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(deleteAdvertise(data.id));
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key={1} style={{fontSize: 14}} onClick={showShowModal}>
        View
      </Menu.Item>
      <Menu.Item
        key={2}
        style={{fontSize: 14}}
        onClick={showModal}
        disabled={permission && !permission.update}>
        Edit
      </Menu.Item>
      <Menu.Item
        key={3}
        style={{fontSize: 14}}
        onClick={showDeleteModal}
        disabled={permission && !permission.delete}>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button type='circle'>
          <MoreOutlined />
        </Button>
      </Dropdown>
      <Modal
        title={'Edit Advertisement'}
        visible={isModalVisible}
        onOk={handleOk}
        footer={false}
        maskClosable={false}
        destroyOnClose={true}
        closeIcon={<ModalCloseConfirm onCancel={handleCancel} />}>
        <EditAdvertisement data={data} closeModel={handleCancel} />
      </Modal>

      <Modal
        title={'View Advertisement Details'}
        visible={isShowModalVisible}
        onOk={handleShowOk}
        footer={false}
        onCancel={handleShowCancel}>
        <ShowAdvertisement data={data} />
      </Modal>
    </>
  );
};

export default AdvertisementActions;

AdvertisementActions.propTypes = {
  data: PropTypes.object,
};
