import React from 'react';
import PropTypes from 'prop-types';
import AppTableContainer from '../../../../@crema/core/AppTableContainer';
import ModuleManagementActions from './ModuleManagementActions';
function ModuleManagementTable({modules, loading}) {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      className: 'customer-table-actions',
      fixed: 'right',
      render: (_, record) => {
        return <ModuleManagementActions data={record} />;
      },
    },
  ];
  return (
    <>
      <AppTableContainer
        className='customer-table'
        hoverColor
        data={modules}
        columns={columns}
        scroll={{x: 'auto'}}
        flag={true}
      />
    </>
  );
}

export default ModuleManagementTable;

ModuleManagementTable.defaultProps = {
  modules: [],
};

ModuleManagementTable.propTypes = {
  modules: PropTypes.array,
  loading: PropTypes.bool,
};
