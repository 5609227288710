import React, {useEffect, useState} from 'react';
import {Form, Button, Input, Row, Col, Tag, Select, Radio, Table} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {addQuestions, getChildQuestions, getQuestions} from 'redux/actions';
import PropTypes from 'prop-types';
import {PlusCircleOutlined} from '@ant-design/icons';
import {useSnackbar} from 'notistack';
import {QuestionsEnum, SubQuestionsEnum} from 'shared/constants/AppEnums';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

function AddQuestions({closeModel}) {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [options, setOptions] = useState([]);
  const [singleQuestion, setSingleQuestion] = useState('');
  const {Option} = Select;
  const questionTypes = ['Radio', 'DropDown', 'Text'];
  const [selectedEnquiryType, setSelectedEnquiryType] = useState(null);
  const [subquery, setSubQuery] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isParent, setIsParent] = useState(false);
  const {childGPQuestions} = useSelector((state) => state.mortageoptions);

  const handleAuthor = (e) => {
    setSingleQuestion(e.target.value);
  };
  console.log('selectedEnquiryType', selectedEnquiryType);
  const removeAuthor = (value) => {
    const newOptions = options.filter((item) => item !== value);
    const updatedTableData = tableData.filter((item) => item.option !== value);
    setOptions(newOptions);
    setTableData(updatedTableData);
  };

  useEffect(() => {
    dispatch(getChildQuestions());
  }, [dispatch]);

  const addQuestion = () => {
    if (singleQuestion.trim().length !== 0) {
      const authorName = options.find((author) => author === singleQuestion);

      if (authorName) {
        enqueueSnackbar('Option already exists.', {variant: 'error'});
      } else {
        setOptions((prev) => {
          const newOptions = [...prev, singleQuestion.trim()];

          setTableData(
            newOptions.map((option) => ({
              key: option,
              option,
              selectedOption: null,
            })),
          );
          return newOptions;
        });
        setSingleQuestion('');
      }
    }
  };

  const onFinish = (values) => {
    const childQuestions = tableData
      .filter((item) => item.selectedOption)
      .map((item) => ({
        childQueuestionId: item.selectedOption,
        parentQuestionOption: item.option,
      }));

    const newObj = {
      question: values.question,
      questionType: values.questionType,
      options: options,
      enquiryType: selectedEnquiryType,
      enquirySubType: subquery,
      order: values.order,
      isParent: isParent == 'true',
      childQuestions: childQuestions,
    };

    if (values?.questionType !== 'Text' && options?.length === 0) {
      enqueueSnackbar('Please add options', {variant: 'warning'});
    } else {
      dispatch(addQuestions(newObj));
      dispatch(getQuestions());
      closeModel();
    }
  };

  const handleDropdownChange = (value, index) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].selectedOption = value;
    setTableData(updatedTableData);
  };

  const columns = [
    {
      title: 'Option',
      dataIndex: 'option',
      key: 'option',
    },
    {
      title: 'Child Question',
      dataIndex: 'selectedOption',
      key: 'selectedOption',
      render: (text, record, index) => (
        <Select
          defaultValue={text}
          style={{width: '100%'}}
          onChange={(value) => handleDropdownChange(value, index)}>
          {childGPQuestions
            ?.filter((x) => x?.enquirySubTypeId === selectedEnquiryType)
            ?.map((option) => (
              <Option key={option?.id} value={option?.id}>
                {option?.question}
              </Option>
            ))}
        </Select>
      ),
    },
  ];

  return (
    <Form
      className='edit-customer-form'
      name='validate_other'
      {...formItemLayout}
      onFinish={onFinish}
      initialValues={{
        question: '',
        order: null,
        questionType: '',
        options: options || [],
        enquiryType: null,
        isParent: true,
      }}>
      <Form.Item
        label='Enquiry Type'
        name='enquiryType'
        rules={[{required: true, message: 'Please add Enquiry type'}]}>
        <Select
          style={{minWidth: '130px', maxWidth: '280px'}}
          name='enquiryType'
          placeholder='Enquiry Type'
          onChange={(value) => {
            setSelectedEnquiryType(value);
          }}>
          {QuestionsEnum.map((qt, i) => (
            <Option key={i} value={qt.value}>
              {qt.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <br />
      {selectedEnquiryType === 1 && (
        <Form.Item
          label='Enquiry Sub Type'
          name='subQuery'
          rules={[
            {required: true, message: 'Please select an enquiry sub type'},
          ]}>
          <Select
            style={{minWidth: '130px', maxWidth: '280px'}}
            placeholder='Select Sub Query'
            onChange={(value) => setSubQuery(value)}>
            {SubQuestionsEnum?.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <br />
      <Form.Item
        label='Question'
        name='question'
        rules={[{required: true, message: 'Please add question'}]}>
        <Input name='question' placeholder='Question' />
      </Form.Item>
      <br />
      <Form.Item
        label='Question Type'
        name='questionType'
        rules={[{required: true, message: 'Please add question type'}]}>
        <Select
          style={{minWidth: '130px', maxWidth: '280px'}}
          name='questionType'
          placeholder='Question Type'>
          {questionTypes.map((qt, i) => (
            <Option key={i} value={qt}>
              {qt}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <br />
      <Form.Item
        label='Is Parent Question?'
        name='isParent'
        onChange={(e) => setIsParent(e.target.value)}
        rules={[{required: true, message: 'Please select an option'}]}>
        <Radio.Group>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <br />
      <Form.Item
        label='Order'
        name='order'
        rules={[{required: true, message: 'Please add order'}]}>
        <Input name='order' placeholder='Input the Order' />
      </Form.Item>
      <br />
      <Form.Item label='* Options' rules={[{required: true, whitespace: true}]}>
        <Row gutter={24}>
          <Col span={20}>
            <Input
              name='options'
              placeholder='Options'
              value={singleQuestion}
              onChange={handleAuthor}
            />
          </Col>
          <Col span={4}>
            <Button
              type='primary'
              icon={<PlusCircleOutlined />}
              onClick={addQuestion}
            />
          </Col>
        </Row>
        <br />
        {options &&
          options?.map((item) => (
            <Tag
              key={item}
              closable
              style={{marginBottom: '5px'}}
              onClose={() => removeAuthor(item)}>
              {item}
            </Tag>
          ))}
      </Form.Item>
      {options.length > 0 && selectedEnquiryType === 1 && isParent && (
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          style={{marginTop: '20px'}}
        />
      )}
      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 6,
        }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AddQuestions;

AddQuestions.propTypes = {
  closeModel: PropTypes.func,
};
