import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  GET_PRE_QULI_USERS,
  SET_SEARCH,
  SET_PAGE,
  SET_ENQUIRY_USER,
} from '../../shared/constants/ActionTypes';

import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getPreQualifiedUser = (limit = 10, enquiryType) => {
  return (dispatch, getState) => {
    const state = getState().PreQualifiedUser;
    dispatch({type: FETCH_START});
    jwtAxios
      .post(
        `/getenquiryusers?enquiryType=${enquiryType ? enquiryType : 1}&pageNo=${
          state.page
        }&keyword=${state.search}&limit=${limit}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_PRE_QULI_USERS,
            payload: {
              data: data.data.data,
              count: data.total,
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const setPage = (page) => {
  return (dispatch) => {
    dispatch({type: SET_PAGE, payload: page});
  };
};
export const setSearch = (search) => {
  return (dispatch) => {
    dispatch({type: SET_SEARCH, payload: search});
  };
};
export const setEnquiryType = (enquiryType) => {
  return (dispatch) => {
    dispatch({type: SET_ENQUIRY_USER, payload: enquiryType});
  };
};
