import React, {useState} from 'react';
import {Button, Menu, Dropdown, Modal} from 'antd';
import {MoreOutlined} from '@ant-design/icons';
import ShowModules from '../ShowModules';
import PropTypes from 'prop-types';

function ModuleManagementActions({data}) {
  const [isShowModalVisible, setIsShowModalVisible] = useState(false);

  const showShowModal = () => {
    setIsShowModalVisible(true);
  };

  const handleShowOk = () => {
    setIsShowModalVisible(false);
  };

  const handleShowCancel = () => {
    setIsShowModalVisible(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key={1} style={{fontSize: 14}} onClick={showShowModal}>
        View
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button type='circle'>
          <MoreOutlined />
        </Button>
      </Dropdown>

      <Modal
        title={'Enquiry Users Details'}
        visible={isShowModalVisible}
        onOk={handleShowOk}
        footer={false}
        onCancel={handleShowCancel}>
        <ShowModules module={data} />
      </Modal>
    </>
  );
}

export default ModuleManagementActions;

ModuleManagementActions.propTypes = {
  data: PropTypes.object,
};
