import {appMode} from '../shared/constants/AppConst';
let envName = `REACT_APP_SERVER_URL_${process.env.REACT_APP_SERVER_MODE?.toUpperCase() ?? 'PRODUCTION'}`;
// export const server =
//   appMode === 'development'
//     ? 'https://cribzzz-api.apps.openxcell.dev'
//     : 'https://api.cribzzzz.com';
export const server = process.env[envName] ?? 'https://api.cribzzzz.com';
// export const server =
//   appMode === "development" ? "https://ooa-backend.api.openxcell.dev"
//     : "https://ooa-backend.api.openxcell.dev";
