import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Users from './Users';
import Profile from './Profile';
import RoleManagement from './RoleManagement';
import PropertyCategory from './PropertyCategory';
import Customers from './Customers';
import ExclusiveProperty from './ExclusiveProperty';
import Mortageoptions from './Mortageoptions';
import Lenders from './Lenders';
import PreQualifiedUser from './PreQualifiedUser';
import advertisement from './Advertisement';
const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    users: Users,
    profile: Profile,
    roleManagement: RoleManagement,
    propertycategoryManagement: PropertyCategory,
    customers: Customers,
    lenders: Lenders,
    exclusiveproperty: ExclusiveProperty,
    mortageoptions: Mortageoptions,
    PreQualifiedUser: PreQualifiedUser,
    advertisement: advertisement,
  });

export default reducers;
