import React, {useEffect, useState} from 'react';
import AppsContainer from '../../../@crema/core/AppsContainer';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import AppsHeader from '../../../@crema/core/AppsContainer/AppsHeader';
import AppsContent from '../../../@crema/core/AppsContainer/AppsContent';
import AppsPagination from '../../../@crema/core/AppsPagination';
import AppInfoView from '../../../@crema/core/AppInfoView';
import {Button, Modal, Select, Typography} from 'antd';
import './index.style.less';
import AppPageMetadata from '../../../@crema/core/AppPageMetadata';
import ModuleManagementTable from './ModuleManagementTable';
import {
  getQuestions,
  setEnquiryType,
  setPage,
  setSearch,
} from 'redux/actions/Mortageoptions';
import AddQuestions from './AddQuestions';
import {SnackbarProvider} from 'notistack';
import ModalCloseConfirm from 'lib/components/ModalCloseConfirm';
import {QuestionsEnum} from 'shared/constants/AppEnums';

function MortageOptions() {
  const {messages} = useIntl();
  const dispatch = useDispatch();
  const {Option} = Select;

  const {questions, page, search, enquiryType} = useSelector(
    (state) => state.mortageoptions,
  );

  const {loading} = useSelector(({common}) => common);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onStatusChange = (value) => {
    dispatch(setEnquiryType(value));
    dispatch(setPage(1));
  };

  const onChange = (page) => {
    dispatch(setPage(page));
  };
  useEffect(() => {
    dispatch(getQuestions());
  }, [dispatch, search, page, enquiryType]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
    };
  }, []);

  return (
    <>
      <AppPageMetadata title='Mortage Options' />
      <AppsContainer
        title={messages['sidebar.admin.mortageOptions']}
        fullView
        type='bottom'>
        <AppsHeader key={'wrap'}>
          <div className='customer-header'>
            <Typography style={{padding: '0 0.5rem'}}>
              Total: {questions.length}
            </Typography>
            <div style={{margin: 'auto 15px 18px auto'}}>
              <Typography style={{marginBottom: 2, fontSize: '12px'}}>
                Select Enquiry Type
              </Typography>
              <Select
                style={{
                  minWidth: '130px',
                  maxWidth: '200px',
                  border: '1px solid gray',
                  borderRadius: 0,
                }}
                defaultValue='Get Pre-Approved'
                placeholder='Select Enquiry Type'
                onChange={onStatusChange}
                onPopupScroll={(e) => e.stopPropagation()}>
                {QuestionsEnum?.map((option, index) => (
                  <Option key={option.value}>{option.name}</Option>
                ))}
              </Select>
            </div>

            <div className='customer-header-right'>
              <Button type='primary' onClick={showModal}>
                Add Question
              </Button>
            </div>
          </div>
        </AppsHeader>

        <AppsContent
          key={'wrap1'}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}>
          <ModuleManagementTable loading={loading} modules={questions} />
        </AppsContent>

        <AppsPagination
          key={'wrap2'}
          className='customer-footer-pagination'
          pageSize={10}
          count={questions.length}
          current={page}
          page={page}
          onChange={onChange}
        />
      </AppsContainer>

      <Modal
        title={messages['admin.addQuestion']}
        visible={isModalVisible}
        onOk={handleOk}
        footer={false}
        width={700}
        maskClosable={false}
        destroyOnClose={true}
        closeIcon={<ModalCloseConfirm onCancel={handleCancel} />}>
        <SnackbarProvider
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}>
          <AddQuestions closeModel={handleCancel} questions={questions} />
        </SnackbarProvider>
      </Modal>

      <AppInfoView />
    </>
  );
}

export default MortageOptions;
