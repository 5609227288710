import {
  SET_PAGE,
  SET_SEARCH,
  GET_ADVERTISEMENTS,
} from '../../shared/constants/ActionTypes';

const initialexclusiveproperty = {
  advertisements: [],
  advertisementCount: 0,
  page: 1,
  search: '',
};

const advertisementReducer = (state = initialexclusiveproperty, action) => {
  switch (action.type) {
    case GET_ADVERTISEMENTS:
      return {
        ...state,
        advertisements: action.payload.data,
        advertisementCount: action.payload.total,
      };

    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };

    default:
      return state;
  }
};

export default advertisementReducer;
