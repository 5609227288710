import React, {useState} from 'react';
import {Button, Menu, Dropdown, Modal} from 'antd';
import {MoreOutlined} from '@ant-design/icons';
import {useIntl} from 'react-intl';
import ShowModules from '../ShowModules';
import {useDispatch} from 'react-redux';
import {deleteQuestion} from 'redux/actions';
import PropTypes from 'prop-types';
import EditQuestions from '../EditQuestions';
import confirm from 'antd/lib/modal/confirm';

function ModuleManagementActions({data}) {
  const {messages} = useIntl();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShowModalVisible, setIsShowModalVisible] = useState(false);
  const dispatch = useDispatch();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showShowModal = () => {
    setIsShowModalVisible(true);
  };

  const handleShowOk = () => {
    setIsShowModalVisible(false);
  };

  const handleShowCancel = () => {
    setIsShowModalVisible(false);
  };

  const showDeleteModal = () => {
    confirm({
      title: 'Are you sure you want to delete this customer?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(deleteQuestion(data.id));
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key={1} style={{fontSize: 14}} onClick={showShowModal}>
        View
      </Menu.Item>
      <Menu.Item key={3} style={{fontSize: 14}} onClick={showDeleteModal}>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button type='circle'>
          <MoreOutlined />
        </Button>
      </Dropdown>

      <Modal
        title={messages['admin.editModule']}
        visible={isModalVisible}
        onOk={handleOk}
        footer={false}
        onCancel={handleCancel}>
        <EditQuestions data={data} closeModel={handleCancel} />
      </Modal>

      <Modal
        title={messages['admin.showModule']}
        visible={isShowModalVisible}
        onOk={handleShowOk}
        footer={false}
        onCancel={handleShowCancel}>
        <ShowModules module={data} />
      </Modal>
    </>
  );
}

export default ModuleManagementActions;

ModuleManagementActions.propTypes = {
  data: PropTypes.object,
};
