import React from 'react';
import PropTypes from 'prop-types';
import AppTableContainer from '../../../../@crema/core/AppTableContainer';
import ModuleManagementActions from './ModuleManagementActions';
import {DateFormat} from 'shared/constants/AppEnums';
import moment from 'moment';
function ModuleManagementTable({modules, loading}) {
  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Question Type',
      dataIndex: 'questionType',
      key: 'questionType',
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => {
        return (
          <p>
            {moment(
              record.updatedAt ? record.updatedAt : record.createdAt,
            ).format(DateFormat.DATETIME_WITH_SLASH)}
          </p>
        );
      },
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      className: 'customer-table-actions',
      fixed: 'right',
      render: (_, record) => {
        return <ModuleManagementActions data={record} />;
      },
    },
  ];
  return (
    <>
      <AppTableContainer
        className='customer-table'
        hoverColor
        data={modules}
        columns={columns}
        scroll={{x: 'auto'}}
      />
      {/* <AppTableContainer
          className="customer-table"
          hoverColor
          data={modules}
          columns={columns}
          loading={loading}
          scroll={{ x: "auto" }}
        /> */}
    </>
  );
}

export default ModuleManagementTable;

ModuleManagementTable.defaultProps = {
  modules: [],
};

ModuleManagementTable.propTypes = {
  modules: PropTypes.array,
  loading: PropTypes.bool,
};
