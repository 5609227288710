import React, {useEffect, useState} from 'react';
import AppsContainer from '../../../@crema/core/AppsContainer';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import AppsHeader from '../../../@crema/core/AppsContainer/AppsHeader';
import AppsContent from '../../../@crema/core/AppsContainer/AppsContent';
import AppInfoView from '../../../@crema/core/AppInfoView';
import {Select, Typography} from 'antd';
import './index.style.less';
import AppPageMetadata from '../../../@crema/core/AppPageMetadata';
import ModuleManagementTable from './ModuleManagementTable';
import {
  getPreQualifiedUser,
  setPage,
  setSearch,
} from 'redux/actions/PreQualifiedUser';
import {QuestionsEnum, QuestionsEnumUsers} from 'shared/constants/AppEnums';
import {setEnquiryType} from 'redux/actions/PreQualifiedUser';

function PreQualifiedUser() {
  const {messages} = useIntl();
  const dispatch = useDispatch();
  const {Option} = Select;

  const {preQulifiedUsers, page, search, enquiryType} = useSelector(
    (state) => state.PreQualifiedUser,
  );

  const {loading} = useSelector(({common}) => common);

  useEffect(() => {
    dispatch(getPreQualifiedUser(10, enquiryType));
  }, [dispatch, search, page, enquiryType]);

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
    };
  }, []);
  const onStatusChange = (value) => {
    dispatch(setEnquiryType(value));
  };
  return (
    <>
      <AppPageMetadata title='Mortage Options' />
      <AppsContainer
        title={messages['sidebar.admin.preQualifiedUser']}
        fullView
        type='bottom'>
        <AppsHeader key={'wrap'}>
          <div className='customer-header'>
            <Typography style={{padding: '0 0.5rem'}}>
              Total: {preQulifiedUsers.length}
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                minWidth: '350px',
              }}>
              <Typography sx={{margin: 10, fontWeight: 400}}>
                Select Enquiry Type :
              </Typography>
              <Select
                style={{
                  minWidth: '130px',
                  maxWidth: '200px',
                  border: '1px solid gray',
                  borderRadius: 0,
                }}
                defaultValue='Get Pre-Approved'
                placeholder='Select Enquiry Type'
                onChange={onStatusChange}
                onPopupScroll={(e) => e.stopPropagation()}>
                {QuestionsEnumUsers?.map((option, index) => (
                  <Option key={option.value}>{option.name}</Option>
                ))}
              </Select>
            </div>
          </div>
        </AppsHeader>
        <AppsContent
          key={'wrap1'}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}>
          <ModuleManagementTable loading={loading} modules={preQulifiedUsers} />
        </AppsContent>
      </AppsContainer>

      <AppInfoView />
    </>
  );
}

export default PreQualifiedUser;
